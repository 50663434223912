// Generated by Framer (e80ae68)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/w8q5QJlSV";

const cycleOrder = ["xwljJH9Xn"];

const serializationHash = "framer-PiheH"

const variantClassNames = {xwljJH9Xn: "framer-v-18sc4kc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, soJfSKWMl: title ?? props.soJfSKWMl ?? "Register"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, soJfSKWMl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xwljJH9Xn", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-18sc4kc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xwljJH9Xn"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-kq3yif"} data-styles-preset={"w8q5QJlSV"}>Register</motion.p></React.Fragment>} className={"framer-d488ba"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"kMCUc7jEZ"} style={{"--framer-paragraph-spacing": "0px"}} text={soJfSKWMl} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-PiheH[data-border=\"true\"]::after, .framer-PiheH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PiheH.framer-bgatxz, .framer-PiheH .framer-bgatxz { display: block; }", ".framer-PiheH.framer-18sc4kc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 20px 10px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-PiheH .framer-d488ba { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PiheH.framer-18sc4kc { gap: 0px; } .framer-PiheH.framer-18sc4kc > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-PiheH.framer-18sc4kc > :first-child { margin-left: 0px; } .framer-PiheH.framer-18sc4kc > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 121
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"soJfSKWMl":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBMH30ny44: React.ComponentType<Props> = withCSS(Component, css, "framer-PiheH") as typeof Component;
export default FramerBMH30ny44;

FramerBMH30ny44.displayName = "Signup Toggle Btn";

FramerBMH30ny44.defaultProps = {height: 51, width: 121};

addPropertyControls(FramerBMH30ny44, {soJfSKWMl: {defaultValue: "Register", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerBMH30ny44, [...sharedStyle.fonts])